import React from 'react';
import { ChevronDown, ChevronUp, Terminal, CheckCircle, Upload, Search, PenTool, FileText, Zap } from 'lucide-react';
import { ScrollArea } from './scroll-area';
import { useGenerationStore, stepDescriptions, type GenerationStep } from '../store/generationSteps';

interface AIFlowDiagramProps {
  isVisible: boolean;
  onToggle: () => void;
  isUploadPage?: boolean;
}

interface StepConfig {
  id: GenerationStep;
  icon: typeof Upload;
  title: string;
  description: string;
  forceCompleted?: boolean;
  forceLoading?: boolean;
}

const AIFlowDiagram: React.FC<AIFlowDiagramProps> = ({
  isVisible,
  onToggle,
  isUploadPage = false
}) => {
  const { currentStep, completedSteps, isProcessing } = useGenerationStore();

  const steps: StepConfig[] = [
    { 
      id: 'document_upload',
      icon: Upload, 
      ...stepDescriptions.document_upload,
      forceCompleted: !isUploadPage,
      forceLoading: isUploadPage && currentStep === 'document_upload' && isProcessing
    },
    { 
      id: 'document_analysis',
      icon: Search, 
      ...stepDescriptions.document_analysis,
      forceCompleted: !isUploadPage,
      forceLoading: isUploadPage && currentStep === 'document_analysis' && isProcessing
    },
    { 
      id: 'user_input',
      icon: PenTool, 
      ...stepDescriptions.user_input,
      forceLoading: currentStep === 'user_input' && isProcessing
    },
    { 
      id: 'outline_generation',
      icon: FileText, 
      ...stepDescriptions.outline_generation,
      forceLoading: currentStep === 'outline_generation' && isProcessing
    },
    { 
      id: 'document_generation',
      icon: Zap, 
      ...stepDescriptions.document_generation,
      forceLoading: currentStep === 'document_generation' && isProcessing
    }
  ];

  return (
    <>
      {/* Toggle Button - Fixed in the corner */}
      <button
        onClick={onToggle}
        className="fixed top-20 right-5 bg-indigo-600 rounded-full p-2.5 text-white hover:bg-indigo-700 transition-colors shadow-lg z-[9999]"
        title={isVisible ? "Hide Progress" : "Show Progress"}
      >
        {isVisible ? <ChevronDown size={24} /> : <ChevronUp size={24} />}
      </button>

      {/* Main Panel */}
      <div className="fixed right-5 top-32 z-[9999]">
        <div className={`bg-white/95 backdrop-blur-sm shadow-2xl rounded-lg w-80 border border-indigo-200 overflow-hidden transition-all duration-300 ease-in-out ${
          isVisible ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-full pointer-events-none'
        }`}>
          {/* Header */}
          <div className="bg-gradient-to-r from-indigo-600 to-purple-600 p-4">
            <div className="flex items-center gap-2 text-white">
              <Terminal size={20} />
              <h3 className="font-semibold">AI Generation Pipeline</h3>
            </div>
          </div>

          {/* Flow Steps in ScrollArea */}
          <ScrollArea className="h-[400px]">
            <div className="p-4 space-y-4">
              {steps.map((step, index) => {
                const isActive = step.id === currentStep;
                const isCompleted = (step.forceCompleted ?? false) || completedSteps.includes(step.id);
                const isLoading = (step.forceLoading ?? false) || (isActive && isProcessing);
                const Icon = step.icon;

                return (
                  <div key={step.id} className="relative">
                    {/* Connector Line */}
                    {index < steps.length - 1 && (
                      <div className={`absolute left-6 top-10 w-0.5 h-8 ${
                        isCompleted ? 'bg-green-500' : 'bg-gray-200'
                      } transition-colors duration-500`} />
                    )}

                    {/* Step Content */}
                    <div className={`flex items-start gap-4 p-3 rounded-lg transition-all duration-300 ${
                      isLoading ? 'bg-indigo-50 scale-105' : ''
                    }`}>
                      <div className={`rounded-full p-2 ${
                        isCompleted 
                          ? 'bg-green-100 text-green-600' 
                          : isLoading
                            ? 'bg-indigo-100 text-indigo-600 animate-pulse'
                            : 'bg-gray-100 text-gray-400'
                      } transition-colors duration-300`}>
                        {isCompleted ? <CheckCircle size={20} /> : <Icon size={20} />}
                      </div>
                      <div>
                        <div className="font-medium text-gray-900">{step.title}</div>
                        <div className="text-sm text-gray-500">{step.description}</div>
                        {isLoading && (
                          <div className="text-xs text-indigo-600 mt-1 flex items-center gap-1">
                            <div className="animate-spin">
                              <ChevronDown size={14} />
                            </div>
                            Processing...
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </ScrollArea>

          {/* Footer */}
          {completedSteps.includes('document_generation') && (
            <div className="bg-green-50 p-3 border-t border-green-100">
              <div className="flex items-center gap-2 text-green-600">
                <CheckCircle size={16} />
                <span className="text-sm font-medium">Generation Complete</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AIFlowDiagram; 