import { io, Socket } from 'socket.io-client';

interface SocketError extends Error {
    type?: string;
    description?: string;
    context?: any;
}

class SocketService {
    private socket: Socket | null = null;
    private currentUrl: string | null = null;
    private readonly PROD_URL = process.env.REACT_APP_SOCKET_URL || 'https://textminer.amplitude.ventures';
    private readonly LOCAL_URL = this.PROD_URL;
    
    private log(...args: any[]) {
        if (process.env.NODE_ENV === 'development') {
            console.log('[SocketService]', ...args);
        }
    }

    private logError(...args: any[]) {
        console.error('[SocketService]', ...args);
    }

    async getSocket(): Promise<Socket | null> {
        if (this.socket?.connected) {
            return this.socket;
        }
        return this.connect();
    }

    async connect(): Promise<Socket | null> {
        this.log('Connecting to socket');
        if (this.socket?.connected) {
            return this.socket;
        }

        try {
            // Try production URL first
            this.log('Trying to connect to production:', this.PROD_URL);
            const prodSocket = await this.tryConnection(this.PROD_URL);
            if (prodSocket) {
                this.currentUrl = this.PROD_URL;
                this.setupSocket(prodSocket);
                return prodSocket;
            }

            // If production fails, try local
            this.log('Production connection failed, trying local:', this.LOCAL_URL);
            const localSocket = await this.tryConnection(this.LOCAL_URL);
            if (localSocket) {
                this.currentUrl = this.LOCAL_URL;
                this.setupSocket(localSocket);
                return localSocket;
            }

            throw new Error('Failed to establish socket connection');
        } catch (error) {
            this.logError('Connection error:', error);
            return null;
        }
    }

    private async tryConnection(url: string): Promise<Socket | null> {
        try {
            const socket = io(url, {
                transports: ['websocket', 'polling'],
                path: '/socket.io',
                reconnection: true,
                reconnectionAttempts: 5,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                timeout: 20000,
                autoConnect: true,
                forceNew: true
            }); 

            return new Promise((resolve) => {
                const timeoutId = setTimeout(() => {
                    this.log(`Connection timeout for ${url}`);
                    socket.close();
                    resolve(null);
                }, 10000);

                socket.on('connect', () => {
                    clearTimeout(timeoutId);
                    this.log(`Connected to ${url}`);
                    resolve(socket);
                });

                socket.on('connect_error', (error) => {
                    this.log(`Failed to connect to ${url}: ${error.message}`);
                    socket.close();
                    resolve(null);
                });
            });
        } catch (error) {
            this.logError(`Error creating socket for ${url}:`, error);
            return null;
        }
    }

    private setupSocket(socket: Socket) {
        this.socket = socket;
        this.setupErrorHandlers(socket);
        this.setupConnectionHandlers(socket);
    }

    private setupErrorHandlers(socket: Socket) {
        socket.on('connect_error', (error: SocketError) => {
            this.logError('Connection error:', {
                message: error.message,
                type: error.type,
                description: error.description,
                context: error.context
            });
        });

        socket.on('error', (error: SocketError) => {
            this.logError('Socket error:', error);
        });
    }

    private setupConnectionHandlers(socket: Socket) {
        socket.on('connect', () => {
            this.log('Connected successfully', {
                socketId: socket.id,
                url: this.currentUrl,
                transport: socket.io?.engine?.transport?.name
            });
        });

        socket.on('disconnect', (reason: string) => {
            this.log('Disconnected:', {
                reason,
                wasConnected: socket.connected,
                lastUrl: this.currentUrl
            });
        });
    }

    getCurrentUrl(): string | null {
        return this.currentUrl;
    }

    disconnect() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }
    }
}

const socketService = new SocketService();
export default socketService;
