import React from 'react';
import { FiMenu, FiChevronDown } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import NavigationButton from './NavigationButton';

interface HeaderProps {
  chatId: string;
  documentMode: string;
  onDocumentModeChange: (mode: string) => void;
  onMenuToggle: () => void;
}

const Header: React.FC<HeaderProps> = ({ chatId, documentMode, onDocumentModeChange, onMenuToggle }) => {
  const navigate = useNavigate();

  return (
    <header className="bg-white shadow-sm p-4 flex items-center justify-between relative">
      <div className="flex items-center gap-4">
        <NavigationButton chatId={chatId} />
        <button
          onClick={onMenuToggle}
          className="text-gray-500 hover:text-gray-700 transition-colors duration-200 md:hidden"
        >
          <FiMenu size={24} />
        </button>
      </div>
      <h1 className="text-xl font-semibold text-gray-800 absolute left-1/2 transform -translate-x-1/2">
        Chat with your documents
      </h1>
      <div className="relative">
        <button 
          className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded' 
          onClick={() => navigate(`/generate/${chatId}`)}
        >
          Generate Document
        </button>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <FiChevronDown size={16} />
        </div>
      </div>
    </header>
  );
};

export default Header;
