import React, { useState, useEffect } from "react";
import { X, Check, ChevronRight, ChevronDown, Book, Save, Loader2 } from 'lucide-react';

interface Section {
  title: string;
  description: string;
  metrics: string;
}

interface ModalProps {
  title: string;
  description: string;
  chapters: string[];
  isGenerating: boolean;
  onClose: () => void;
  onApprove: () => void;
  onSetBookTitle: (value: string) => void;
  onSetDescription: (value: string) => void;
  onSetChapters: (value: string[]) => void;
  isGeneratingDocument: boolean;
  onDelete?: () => void;
}

const GeneratedOutlineModal: React.FC<ModalProps> = ({
  chapters,
  isGenerating,
  onClose,
  onApprove,
  isGeneratingDocument,
  onDelete,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [expandedSections, setExpandedSections] = useState<Set<number>>(new Set());
  const [sections, setSections] = useState<Section[]>([]);
  const [parseError, setParseError] = useState<string | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (chapters && chapters.length > 0) {
      try {
        const content = chapters[0];
        if (typeof content !== 'string') {
          throw new Error('Invalid content format');
        }

        // Split content into lines and process headers
        const lines = content.split('\n');
        const sectionsArray: Section[] = [];
        let currentSection: Section | null = null;
        let currentContent: string[] = [];

        for (let line of lines) {
          // Check for headers: markdown headers, numbered sections, bold sections, or capitalized categories
          if (
            line.match(/^#{1,6}\s+.*/) ||     // Markdown headers like "### Title" or "#### Title"
            line.match(/^\d+\.\s+[A-Z]/) ||  // Numbered sections like "1. Title"
            line.match(/^\*\*.*\*\*$/) ||    // Bold sections like "**Title**"
            line.match(/^[A-Z][A-Za-z\s]+:?$/) || // Capitalized sections like "Title:"
            line.match(/^[A-Z][A-Za-z\s]+\s+and\s+[A-Z][A-Za-z\s]+/) // Combined titles like "Mission and Services"
          ) {
            // If we have a current section, save it
            if (currentSection) {
              currentSection.description = currentContent.join('\n').trim();
              sectionsArray.push(currentSection);
            }
            // Start new section, removing any markers
            currentSection = {
              title: line
                .replace(/^#{1,6}\s+/, '')     // Remove markdown headers (any number of #)
                .replace(/^\d+\.\s+/, '')   // Remove leading numbers
                .replace(/^\*\*|\*\*$/g, '') // Remove ** markers
                .trim(),
              description: '',
              metrics: ''
            };
            currentContent = [];
          } else if (currentSection && line.trim()) {
            // Clean up bullet points and add proper spacing
            let cleanedLine = line.trim()
              .replace(/^\s*[•-]\s*/, '• ')  // Convert dashes and bullets to consistent format
              .replace(/\*\*/g, '')          // Remove bold markers
              .replace(/^\s*/, '');          // Remove leading spaces
            
            currentContent.push(cleanedLine);
          }
        }

        // Don't forget to add the last section
        if (currentSection) {
          currentSection.description = currentContent.join('\n').trim();
          sectionsArray.push(currentSection);
        }

        setSections(sectionsArray);
        setParseError(null);
      } catch (error) {
        console.error('Error parsing sections:', error);
        setParseError(error instanceof Error ? error.message : 'Failed to parse sections');
        setSections([]);
      }
    }
  }, [chapters]);

  const toggleSection = (index: number) => {
    setExpandedSections(prev => {
      const newSet = new Set(prev);
      if (newSet.has(index)) {
        newSet.delete(index);
      } else {
        newSet.add(index);
      }
      return newSet;
    });
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg w-full max-w-2xl relative overflow-hidden shadow-2xl transform transition-all duration-300 ease-in-out hover:scale-[1.02]">
        {/* Header */}
        <div className="bg-indigo-600 text-white p-6 flex justify-between items-center">
          <h3 className="text-2xl font-bold flex items-center">
            <Book className="mr-2" /> Evaluation Categories
          </h3>
          <div className="flex items-center gap-2">
            {onDelete && (
              <button
                onClick={onDelete}
                className="text-white hover:text-red-200 transition-colors duration-200 px-3 py-1 rounded border border-white/20 hover:border-red-200 flex items-center gap-1"
              >
                <X className="h-4 w-4" />
                Delete
              </button>
            )}
            <button
              className="text-white hover:text-indigo-200 transition-colors duration-200"
              onClick={onClose}
            >
              <X className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="p-6 max-h-[calc(100vh-200px)] overflow-y-auto">
          {isLoading ? (
            <div className="space-y-4 animate-pulse">
              <div className="h-8 bg-gray-200 rounded w-3/4"></div>
              <div className="h-4 bg-gray-200 rounded w-full"></div>
              <div className="h-4 bg-gray-200 rounded w-5/6"></div>
              <div className="h-4 bg-gray-200 rounded w-4/6"></div>
            </div>
          ) : parseError ? (
            <div className="text-red-500 p-4 bg-red-50 rounded-lg">
              <p className="font-medium">Error parsing outline:</p>
              <p>{parseError}</p>
            </div>
          ) : sections.length > 0 ? (
            <div className="space-y-4">
              {sections.map((section, index) => (
                <div key={index} className="border rounded-lg overflow-hidden">
                  <button
                    onClick={() => toggleSection(index)}
                    className="w-full p-4 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
                  >
                    <span className="font-semibold text-left">{section.title}</span>
                    {expandedSections.has(index) ? (
                      <ChevronDown className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronRight className="h-5 w-5 text-gray-500" />
                    )}
                  </button>
                  {expandedSections.has(index) && (
                    <div className="p-4 bg-white border-t">
                      {section.description.split('\n').map((line, i) => {
                        // Clean up the line
                        const cleanLine = line
                          .replace(/\*\*/g, '')  // Remove bold markers
                          .replace(/^-\s*/, ''); // Remove leading dash

                        // If it's a bullet point (starts with a dash)
                        if (line.trim().startsWith('-')) {
                          return (
                            <div key={i} className="flex mb-2">
                              <span className="mr-2">•</span>
                              <span className="flex-1">{cleanLine}</span>
                            </div>
                          );
                        }
                        
                        // Regular line
                        return cleanLine ? (
                          <p key={i} className="text-gray-700 mb-2">{cleanLine}</p>
                        ) : null;
                      })}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-600">No outline available.</p>
          )}
        </div>

        {/* Footer */}
        <div className="bg-gray-100 px-6 py-4 flex justify-end space-x-4">
          <button
            className="py-2 px-4 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 transition-colors duration-200 flex items-center"
            onClick={onClose}
          >
            <X className="mr-2" size={16} /> Close
          </button>
          <button
            onClick={onApprove}
            disabled={isGeneratingDocument || sections.length === 0}
            className={`px-4 py-2 rounded-lg text-white font-medium flex items-center gap-2 ${
              isGeneratingDocument || sections.length === 0
                ? 'bg-gray-400 cursor-not-allowed' 
                : 'bg-green-600 hover:bg-green-700'
            }`}
          >
            {isGeneratingDocument ? (
              <>
                <svg 
                  className="animate-spin h-5 w-5 text-white" 
                  xmlns="http://www.w3.org/2000/svg" 
                  fill="none" 
                  viewBox="0 0 24 24"
                >
                  <circle 
                    className="opacity-25" 
                    cx="12" 
                    cy="12" 
                    r="10" 
                    stroke="currentColor" 
                    strokeWidth="4"
                  />
                  <path 
                    className="opacity-75" 
                    fill="currentColor" 
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <span>Starting Generation...</span>
              </>
            ) : (
              'Approve & Generate'
            )}
          </button>
        </div>
      </div>
      {isGenerating && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-full p-3">
            <Loader2 className="animate-spin text-indigo-600" size={32} />
          </div>
        </div>
      )}
    </div>
  );
};

export default GeneratedOutlineModal;

