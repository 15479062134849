import React, { useState, useEffect, KeyboardEvent } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronRight, ChevronLeft, X, Send, ArrowLeft } from 'lucide-react';

interface Question {
  id: string;
  text: string;
}

interface TypeformLikeQuestionsProps {
  questions: Question[];
  onComplete: (answers: Record<string, string>) => void;
  onClose: () => void;
}

const TypeformLikeQuestions: React.FC<TypeformLikeQuestionsProps> = ({ questions, onComplete, onClose }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState<Record<string, string>>({});
  const [isTyping, setIsTyping] = useState(false);
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowHint(true), 3000);
    return () => clearTimeout(timer);
  }, [currentQuestionIndex]);

  const handleAnswer = (questionId: string, answer: string) => {
    setIsTyping(true);
    setAnswers(prev => ({ ...prev, [questionId]: answer }));
    setShowHint(false);
  };

  const handleClose = () => {
    setAnswers({});
    setCurrentQuestionIndex(0);
    onClose();
  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      if (currentQuestion.id === 'qPages' && answers['qPages'] === '') {
        return;
      }
      setCurrentQuestionIndex(prev => prev + 1);
      setIsTyping(false);
      setShowHint(false);
    } else {
      onComplete(answers);
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(prev => prev - 1);
      setIsTyping(false);
      setShowHint(false);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && answers[currentQuestion.id]?.trim()) {
      e.preventDefault();
      goToNextQuestion();
    }
  };

  const currentQuestion = questions[currentQuestionIndex];
  const progress = ((currentQuestionIndex + 1) / questions.length) * 100;

  return (
    <motion.div 
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-gradient-to-br from-indigo-900/90 to-purple-900/90 backdrop-blur-sm flex items-center justify-center z-50"
    >
      <motion.div 
        className="bg-white dark:bg-gray-800 p-8 rounded-2xl shadow-2xl w-full max-w-2xl relative overflow-hidden"
        initial={{ scale: 0.9, y: 20 }}
        animate={{ scale: 1, y: 0 }}
        transition={{ type: "spring", duration: 0.5 }}
      >
        {/* Progress Bar */}
        <div className="absolute top-0 left-0 w-full h-1 bg-gray-200">
          <motion.div
            className="h-full bg-gradient-to-r from-indigo-500 to-purple-500"
            initial={{ width: 0 }}
            animate={{ width: `${progress}%` }}
            transition={{ duration: 0.5 }}
          />
        </div>

        <button 
          onClick={handleClose}
          className="absolute top-4 right-4 p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200 group"
          aria-label="Close questions"
        >
          <X size={20} className="text-gray-500 group-hover:text-gray-700 dark:text-gray-400 dark:group-hover:text-gray-200" />
        </button>

        <AnimatePresence mode="wait">
          <motion.div
            key={currentQuestion.id}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ type: "spring", stiffness: 100 }}
            className="space-y-6"
          >
            <motion.h2 
              className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-500 to-purple-500"
              initial={{ y: -20 }}
              animate={{ y: 0 }}
            >
              {currentQuestion.text}
            </motion.h2>

            <div className="relative">
              <input
                type="text"
                className="w-full p-4 text-lg border-2 border-gray-200 dark:border-gray-600 rounded-xl 
                         focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 
                         dark:text-white transition-all duration-200 pr-12"
                value={answers[currentQuestion.id] || ''}
                onChange={(e) => handleAnswer(currentQuestion.id, e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Type your answer here..."
                autoFocus
              />
              {answers[currentQuestion.id]?.trim() && (
                <motion.button
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  className="absolute right-3 top-1/2 -translate-y-1/2 p-2 rounded-full 
                           bg-indigo-500 text-white hover:bg-indigo-600 transition-colors"
                  onClick={goToNextQuestion}
                >
                  <Send size={18} />
                </motion.button>
              )}
            </div>

            {showHint && !answers[currentQuestion.id] && (
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-sm text-gray-500 dark:text-gray-400 italic"
              >
                Press Enter to continue after typing your answer...
              </motion.p>
            )}

            <div className="flex justify-between items-center pt-4">
              <button
                onClick={goToPreviousQuestion}
                disabled={currentQuestionIndex === 0}
                className="flex items-center px-4 py-2 text-gray-600 dark:text-gray-300 
                         disabled:opacity-50 transition-all duration-200 hover:text-indigo-500 
                         disabled:hover:text-gray-600"
              >
                <ArrowLeft className="mr-2" size={18} />
                Previous
              </button>

              <div className="text-sm font-medium text-gray-500 dark:text-gray-400">
                {currentQuestionIndex + 1} of {questions.length}
              </div>

              {currentQuestionIndex === questions.length - 1 && (
                <motion.button
                  onClick={() => onComplete(answers)}
                  className="px-6 py-2 bg-gradient-to-r from-indigo-500 to-purple-500 
                           text-white rounded-lg font-medium hover:from-indigo-600 
                           hover:to-purple-600 transition-all duration-200 shadow-lg 
                           hover:shadow-indigo-500/25"
                  whileHover={{ scale: 1.05 }}
                >
                  Finish
                  <ChevronRight className="ml-2" />
                </motion.button>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
        <div className="mt-4 text-center text-sm text-gray-500">
          Question {currentQuestionIndex + 1} of {questions.length}
        </div>
      </motion.div>
    </motion.div>
  );
};
export default TypeformLikeQuestions;

