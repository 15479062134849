import { create } from 'zustand';

export type GenerationStep = 
  | 'document_upload'
  | 'document_analysis'
  | 'user_input'
  | 'outline_generation'
  | 'document_generation';

interface GenerationState {
  currentStep: GenerationStep;
  completedSteps: GenerationStep[];
  isProcessing: boolean;
  setCurrentStep: (step: GenerationStep) => void;
  completeStep: (step: GenerationStep) => void;
  resetSteps: () => void;
  isStepCompleted: (step: GenerationStep) => boolean;
  setProcessing: (processing: boolean) => void;
}

export const useGenerationStore = create<GenerationState>((set, get) => ({
  currentStep: 'document_upload',
  completedSteps: [],
  isProcessing: false,
  setCurrentStep: (step) => {
    set((state) => ({
      currentStep: step,
      // Reset processing state when changing steps
      isProcessing: false
    }));
  },
  completeStep: (step) => set((state) => {
    if (state.completedSteps.includes(step)) {
      return state;
    }
    const nextStep = getNextStep(step);
    return {
      completedSteps: [...state.completedSteps, step],
      currentStep: nextStep,
      // Start processing for the next step
      isProcessing: true
    };
  }),
  resetSteps: () => set({ 
    currentStep: 'document_upload', 
    completedSteps: [],
    isProcessing: false 
  }),
  isStepCompleted: (step) => get().completedSteps.includes(step),
  setProcessing: (processing) => set({ isProcessing: processing })
}));

function getNextStep(currentStep: GenerationStep): GenerationStep {
  const steps: GenerationStep[] = [
    'document_upload',
    'document_analysis',
    'user_input',
    'outline_generation',
    'document_generation'
  ];
  
  const currentIndex = steps.indexOf(currentStep);
  return currentIndex < steps.length - 1 ? steps[currentIndex + 1] : currentStep;
}

export const stepDescriptions: Record<GenerationStep, { title: string; description: string }> = {
  document_upload: {
    title: 'Document Upload',
    description: 'Uploading and processing files'
  },
  document_analysis: {
    title: 'Document Analysis',
    description: 'Analyzing document content and structure'
  },
  user_input: {
    title: 'User Input & Analysis',
    description: 'Processing user requirements and preferences'
  },
  outline_generation: {
    title: 'Outline Generation',
    description: 'Creating document structure and outline'
  },
  document_generation: {
    title: 'Document Generation',
    description: 'Generating final content with AI'
  }
};