import { jsPDF } from "jspdf";
import DOMPurify from 'dompurify';
import showdown from 'showdown';

interface PdfGenerationOptions {
  margins?: [number, number, number, number];
  fontSize?: {
    body: number;
    h1: number;
    h2: number;
    h3: number;
    h4: number;
    code: number;
  };
}

declare module 'jspdf' {
  interface jsPDF {
    autoTable: (options: any) => void;
    lastAutoTable: {
      finalY: number;
    };
  }
}

export const generatePDF = async (
  markdownContent: string,
  title: string,
  options: PdfGenerationOptions = {}
): Promise<{ blob: Blob; filename: string }> => {
  const {
    margins = [30, 20, 30, 20],
    fontSize = {
      body: 11,
      h1: 20,
      h2: 16,
      h3: 14,
      h4: 12,
      code: 10,
    },
  } = options;

  // Convert markdown to HTML
  const converter = new showdown.Converter({
    tables: true,
    strikethrough: true
  });
  
  const htmlContent = converter.makeHtml(markdownContent);
  const cleanHtml = DOMPurify.sanitize(htmlContent);

  // Create PDF
  const pdf = new jsPDF({
    unit: 'pt',
    format: 'a4',
    orientation: 'portrait'
  });

  // Set up fonts
  pdf.setFont("helvetica");
  
  // Calculate page width excluding margins
  const pageWidth = pdf.internal.pageSize.getWidth();
  const pageHeight = pdf.internal.pageSize.getHeight();
  const contentWidth = pageWidth - margins[1] - margins[3];

  let yPosition = margins[0];

  // Create temporary element to parse HTML
  const div = document.createElement('div');
  div.innerHTML = cleanHtml;
  
  // Process each element
  const processElement = async (element: Element) => {
    const tagName = element.tagName.toLowerCase();
    const text = element.textContent?.trim() || '';
    if (!text) return;

    // Check if we need a new page
    if (yPosition + 40 > pageHeight - margins[2]) {
      pdf.addPage();
      yPosition = margins[0];
    }

    switch (tagName) {
      case 'h1':
        pdf.setFontSize(fontSize.h1);
        pdf.setFont("helvetica", "bold");
        const h1Lines = pdf.splitTextToSize(text, contentWidth);
        h1Lines.forEach((line: string) => {
          pdf.text(line, margins[3], yPosition);
          yPosition += fontSize.h1 + 10;
        });
        yPosition += 20;
        break;

      case 'h2':
        pdf.setFontSize(fontSize.h2);
        pdf.setFont("helvetica", "bold");
        const h2Lines = pdf.splitTextToSize(text, contentWidth);
        h2Lines.forEach((line: string) => {
          pdf.text(line, margins[3], yPosition);
          yPosition += fontSize.h2 + 8;
        });
        yPosition += 15;
        break;

      case 'p':
        pdf.setFontSize(fontSize.body);
        pdf.setFont("helvetica", "normal");
        const lines = pdf.splitTextToSize(text, contentWidth);
        lines.forEach((line: string) => {
          if (yPosition + fontSize.body + 5 > pageHeight - margins[2]) {
            pdf.addPage();
            yPosition = margins[0];
          }
          pdf.text(line, margins[3], yPosition);
          yPosition += fontSize.body + 5;
        });
        yPosition += 10;
        break;

      case 'ul':
      case 'ol':
        const items = element.getElementsByTagName('li');
        pdf.setFontSize(fontSize.body);
        pdf.setFont("helvetica", "normal");
        
        Array.from(items).forEach((item, index) => {
          if (yPosition + fontSize.body + 5 > pageHeight - margins[2]) {
            pdf.addPage();
            yPosition = margins[0];
          }
          const bullet = tagName === 'ol' ? `${index + 1}.` : '•';
          const itemText = pdf.splitTextToSize(item.textContent || '', contentWidth - 20);
          itemText.forEach((line: string, lineIndex: number) => {
            if (lineIndex === 0) {
              pdf.text(bullet, margins[3], yPosition);
              pdf.text(line, margins[3] + 20, yPosition);
            } else {
              pdf.text(line, margins[3] + 20, yPosition);
            }
            yPosition += fontSize.body + 5;
          });
        });
        yPosition += 10;
        break;
    }

    // Process child elements
    for (const child of Array.from(element.children)) {
      await processElement(child);
    }
  };

  // Process all elements
  for (const element of Array.from(div.children)) {
    await processElement(element);
  }

  return {
    blob: pdf.output('blob'),
    filename: `${title.replace(/[^a-zA-Z0-9]/g, '_')}.pdf`
  };
};

