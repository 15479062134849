import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { FiMessageSquare, FiFileText } from 'react-icons/fi'

const OptionsPage: React.FC = () => {
  const { chatId } = useParams<{ chatId: string }>() // Extract chatId from URL params

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="text-center bg-white rounded-lg shadow-md p-8 w-full max-w-md">
        <h2 className="text-3xl font-bold mb-8 text-gray-800">What would you like to do next?</h2>
        
        <div className="mb-6 space-y-4">
          {/* Link to chat with the document using the chatId */}
          <Link to={`/chat/${chatId}`} className="w-full flex items-center justify-center bg-blue-600 text-white py-3 px-6 rounded-lg font-semibold text-lg hover:bg-blue-500 transition-all duration-300">
            <FiMessageSquare className="mr-2" />
            Chat with your document
          </Link>

          {/* Link to generate docs using the chatId */}
          <Link to={`/generate/${chatId}`} className="w-full flex items-center justify-center bg-green-600 text-white py-3 px-6 rounded-lg font-semibold text-lg hover:bg-green-500 transition-all duration-300">
            <FiFileText className="mr-2" />
            Generate Docs
          </Link>
        </div>
      </div>
    </div>
  )
}

export default OptionsPage
