import React, { useState } from 'react';
import { FiTerminal, FiChevronDown, FiChevronRight, FiInfo } from 'react-icons/fi';

interface PromptInfo {
  name: string;
  description: string;
  prompt: string;
}

interface SystemPromptTerminalProps {
  systemPrompt: string;
  relatedPrompts: PromptInfo[];
}

const SystemPromptTerminal: React.FC<SystemPromptTerminalProps> = ({ systemPrompt, relatedPrompts }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [expandedPrompts, setExpandedPrompts] = useState<Record<string, boolean>>({});
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});

  const togglePrompt = (name: string) => {
    setExpandedPrompts(prev => ({
      ...prev,
      [name]: !prev[name]
    }));
  };

  const toggleSection = (name: string) => {
    setExpandedSections(prev => ({
      ...prev,
      [name]: !prev[name]
    }));
  };

  return (
    <div className="fixed bottom-0 right-0 z-50">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="fixed bottom-4 right-4 bg-gray-800 text-white p-2 rounded-full flex items-center justify-center hover:bg-gray-700 transition-colors shadow-lg"
        title="View Prompts Overview"
      >
        <FiTerminal size={20} />
      </button>
      
      {isOpen && (
        <div className="fixed bottom-16 right-4 w-[32rem] bg-gray-900 text-gray-100 rounded-lg shadow-xl max-h-[80vh] overflow-y-auto">
          <div className="flex justify-between items-center p-3 border-b border-gray-700 sticky top-0 bg-gray-900">
            <span className="text-sm font-semibold">Prompts Overview</span>
            <button 
              onClick={() => setIsOpen(false)}
              className="text-gray-400 hover:text-white"
            >
              <FiChevronDown size={20} />
            </button>
          </div>
          
          <div className="divide-y divide-gray-700">
            {/* Main System Prompt Section */}
            <div className="p-4">
              <div 
                className="flex items-center justify-between cursor-pointer"
                onClick={() => toggleSection('system')}
              >
                <h3 className="text-sm font-semibold text-blue-400">System Prompt</h3>
                <FiChevronRight
                  size={16}
                  className={`transform transition-transform ${expandedSections['system'] ? 'rotate-90' : ''}`}
                />
              </div>
              {expandedSections['system'] && (
                <pre className="mt-2 font-mono text-sm whitespace-pre-wrap bg-gray-800 p-3 rounded">
                  {systemPrompt}
                </pre>
              )}
            </div>

            {/* Related Prompts Section */}
            <div className="p-4">
              <div 
                className="flex items-center justify-between cursor-pointer mb-2"
                onClick={() => toggleSection('related')}
              >
                <h3 className="text-sm font-semibold text-blue-400">API Endpoints & Prompts</h3>
                <FiChevronRight
                  size={16}
                  className={`transform transition-transform ${expandedSections['related'] ? 'rotate-90' : ''}`}
                />
              </div>
              {expandedSections['related'] && (
                <div className="space-y-2">
                  {relatedPrompts.map((prompt, index) => (
                    <div 
                      key={index}
                      className="bg-gray-800 p-3 rounded hover:bg-gray-750"
                    >
                      <div 
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => togglePrompt(prompt.name)}
                      >
                        <div className="flex items-center space-x-2">
                          <FiChevronRight
                            size={16}
                            className={`transform transition-transform ${expandedPrompts[prompt.name] ? 'rotate-90' : ''}`}
                          />
                          <h4 className="text-sm font-medium">{prompt.name}</h4>
                        </div>
                        <FiInfo 
                          size={16} 
                          className="text-gray-400"
                          title={prompt.description}
                        />
                      </div>
                      {expandedPrompts[prompt.name] && (
                        <pre className="mt-2 text-xs font-mono whitespace-pre-wrap text-gray-300 border-t border-gray-700 pt-2">
                          {prompt.prompt}
                        </pre>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SystemPromptTerminal; 