'use client'

import React, { useState, useCallback, useRef, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../components/dialog"
import { Progress } from "../components/progress"
import { ScrollArea } from "../components/scroll-area"
import socketService from '../services/socketService';
import { FileText, File, AlertCircle, CheckCircle, Loader, X, Upload } from 'lucide-react';
import AIFlowDiagram from '../components/AIFlowDiagram';
import { Badge } from "../components/badge";
import Header from '../components/Header';
import MarkdownModal from '../components/MarkDownModal';
import { useGenerationStore, type GenerationStep } from '../store/generationSteps'

interface ProcessingStep {
    step: 'idle' | 'uploading' | 'reading' | 'storing' | 'embedding' | 'summarizing' | 'completed';
    progress: number;
    currentOperation: string;
    error?: string | null;
    currentChunk: number;
    totalChunks: number;
}

interface Upload {
  id: string;
  file_name: string;
  upload_date: string;
  purpose: string;
}

const FileUploadForm = () => {
  const [files, setFiles] = useState<File[]>([])
  const [title, setTitle] = useState('')
  const [purpose, setPurpose] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [uploadProgress, setUploadProgress] = useState<string[]>([])
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [totalFiles, setTotalFiles] = useState(0)
  const [processedFiles, setProcessedFiles] = useState(0)
  const [progressPercentage, setProgressPercentage] = useState(0)
  const [uploadId, setUploadId] = useState<string>('')
  const [documentId, setDocumentId] = useState<string>('')
  const [fileStatuses, setFileStatuses] = useState<{[key: string]: 'pending' | 'processing' | 'completed' | 'error'}>({})
  const [showProgressDialog, setShowProgressDialog] = useState(false)
  const [isUploading, setIsUploading] = useState(false); // Added state for upload progress
  const navigate = useNavigate();
  const [processingStep, setProcessingStep] = useState<ProcessingStep>({
    step: 'idle',
    progress: 0,
    currentOperation: '',
    currentChunk: 0,
    totalChunks: 0
  });

  const [markdownContent, setMarkdownContent] = useState("");
  const [showMarkdownModal, setShowMarkdownModal] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [fileValidation, setFileValidation] = useState<{[key: string]: { valid: boolean; message: string }}>({});
  const { setCurrentStep, completeStep, setProcessing } = useGenerationStore();
  const [isAnalysisComplete, setIsAnalysisComplete] = useState(false);
  const [showFlowDiagram, setShowFlowDiagram] = useState(false);
  const [previousUploads, setPreviousUploads] = useState<Upload[]>([]);
  const [showUploadsModal, setShowUploadsModal] = useState(false);

  // Initialize the first step when component mounts
  useEffect(() => {
    setCurrentStep('document_upload');
  }, [setCurrentStep]);

  // Handle file processing completion
  useEffect(() => {
    if (processedFiles >= totalFiles && totalFiles > 0) {
      // All files have been processed (either completed or errored)
      setTimeout(() => {
        setShowProgressDialog(false);
        // Reset states
        setProcessedFiles(0);
        setProgressPercentage(0);
        setUploadProgress([]);
        setIsUploading(false);
        
        // Complete document_upload step and move to analysis
        completeStep('document_upload');
      }, 1500);
    }
  }, [processedFiles, totalFiles, completeStep]);

  // Socket event handlers
  useEffect(() => {
    const setupSocket = async () => {
      try {
        const socket = await socketService.getSocket();
        if (!socket) {
          setErrorMessage('Could not establish socket connection');
          return;
        }

        // Add connection status handlers
        socket.on('connect', () => {
          console.log(`Connected to server with SID: ${socket.id}`);
        });

        socket.on('connect_error', (error: Error) => {
          console.error(`Connection error: ${error.message}`);
          setErrorMessage(`Socket connection error: ${error.message}`);
         
        });

        socket.on('disconnect', () => {
          console.log('Disconnected from server');
        });

        socket.on('process_started', (data: { file_name: string, operation: string }) => {
          addProgressMessage(`${data.operation}: ${data.file_name}`);
          setShowProgressDialog(true);
          completeStep('document_upload');
          setCurrentStep('document_analysis');
          setProcessing(true);
          setProcessingStep(prev => ({ 
            ...prev, 
            step: 'reading', 
            progress: 10,
            currentOperation: data.operation
          }));
        });

        socket.on('file_processing_read', (data: { file_name: string; operation: string; total_chunks: number }) => {
          setProcessingStep(prev => ({
            ...prev,
            step: 'reading',
            currentOperation: data.operation,
            totalChunks: data.total_chunks,
            progress: 20
          }));
        });

        socket.on('storing_file', (data: { file_name: string; operation: string; total_chunks: number }) => {
          setProcessingStep(prev => ({
            ...prev,
            step: 'storing',
            currentOperation: `${data.operation} (0/${data.total_chunks})`,
            totalChunks: data.total_chunks,
            progress: 20
          }));
        });

        socket.on('extracting_relevant_contents', (data: { 
          file_name: string, 
          total_chunks: number,
          operation: string 
        }) => {
          addProgressMessage(`${data.operation}: ${data.file_name}`);
          setProcessingStep(prev => ({ 
            ...prev, 
            step: 'summarizing', 
            progress: 40,
            totalChunks: data.total_chunks,
            currentChunk: 0,
            currentOperation: data.operation
          }));
        });

        socket.on('chunk_processed', (data: { 
          chunk_num: number; 
          total_chunks: number; 
          operation: string; 
          progress: number;
          file_name: string; 
        }) => {
          // Adjust progress calculation for single chunk case
          const progressPercent = data.total_chunks === 1 
            ? 80  // For single chunk, move directly to 80%
            : Math.round((data.chunk_num / data.total_chunks) * 80) + 20;

          setProcessingStep(prev => ({
            ...prev,
            step: 'embedding',
            currentOperation: data.total_chunks === 1 
              ? 'Processing document' 
              : `Processing chunk ${data.chunk_num}/${data.total_chunks}`,
            currentChunk: data.chunk_num,
            totalChunks: data.total_chunks,
            progress: progressPercent
          }));

          // If this is the last chunk, mark as completed and move to next phase
          if (data.chunk_num === data.total_chunks) {
            setProcessedFiles(prev => prev + 1);
            setFileStatuses(prev => ({
              ...prev,
              [data.file_name]: 'completed'
            }));
            // Move to next phase immediately for single chunk
            if (data.total_chunks === 1) {
              setProcessingStep(prev => ({
                ...prev,
                step: 'summarizing',
                progress: 90,
                currentOperation: 'Finalizing document processing'
              }));
            }
          }
        });

        socket.on('processing_error', (data: { error: string }) => {
          setErrorMessage(data.error);
          addProgressMessage(`Error: ${data.error}`);
          setProcessingStep(prev => ({
            ...prev,
            error: data.error
          }));
        });

        socket.on('file_processing_completed', (data: { file_name: string; document_id: string }) => {
          setProcessingStep(prev => ({
            ...prev,
            step: 'completed',
            currentOperation: 'Processing completed',
            progress: 100
          }));
          setDocumentId(data.document_id);
          setProcessedFiles(prev => prev + 1);
          setFileStatuses(prev => ({
            ...prev,
            [data.file_name]: 'completed'
          }));
        });

        socket.on('summary_started', (data: {
          file_name: string,
          document_id: string,
          operation: string
        }) => {
          addProgressMessage(`${data.operation}`);
          setProcessingStep(prev => ({
            ...prev,
            step: 'summarizing',
            progress: 95,
            currentOperation: data.operation
          }));
        });

        socket.on('summary_completed', (data: {
          file_name: string,
          document_id: string,
          operation: string
        }) => {
          addProgressMessage(`${data.operation}`);
          setProcessingStep(prev => ({
            ...prev,
            step: 'completed',
            progress: 100,
            currentOperation: data.operation
          }));
          completeStep('document_analysis');
          
          // Wait a moment to show completion state, then navigate
          setTimeout(() => {
            setShowProgressDialog(false);
            navigate(`/options/${data.document_id}`);
          }, 1500);
        });

        socket.on('summary_error', (data: {
          file_name: string,
          document_id: string,
          error: string,
          operation: string
        }) => {
          addProgressMessage(`Error: ${data.error}`);
          setProcessingStep(prev => ({
            ...prev,
            error: data.error,
            currentOperation: data.operation
          }));
        });

        socket.on('file_processing_error', (data: { file_name: string, error: string }) => {
          addProgressMessage(`Error processing ${data.file_name}: ${data.error}`);
          setFileStatuses(prev => ({ ...prev, [data.file_name]: 'error' }));
          setProcessedFiles((prev) => prev + 1);
        });

        socket.on('process_completed', (data: { message?: string, upload_id?: string }) => {
          addProgressMessage('All files processed successfully');
          setIsUploading(false);
          setShowProgressDialog(false);
          // Navigate to options page after successful upload
          if (data.upload_id) {
            navigate(`/options/${data.upload_id}`);
          }
        });

        socket.on('upload_cancelled', (data: { message?: string }) => {
          addProgressMessage('Upload cancelled successfully.');
          setIsUploading(false);
        });

        socket.on('embedding_started', (data: {
          file_name: string,
          operation: string
        }) => {
          setProcessingStep(prev => ({
            ...prev,
            step: 'embedding',
            progress: 85,
            currentOperation: 'Generating embeddings'
          }));
        });

        socket.on('pinecone_upload_started', (data: {
          file_name: string,
          operation: string
        }) => {
          setProcessingStep(prev => ({
            ...prev,
            step: 'embedding',
            progress: 90,
            currentOperation: 'Uploading to vector database'
          }));
        });

        socket.on('pinecone_upload_completed', (data: {
          file_name: string,
          operation: string
        }) => {
          setProcessingStep(prev => ({
            ...prev,
            step: 'embedding',
            progress: 95,
            currentOperation: 'Vector database upload complete'
          }));
        });

        // Store socket cleanup function
        return () => {
          socket.off('connect');
          socket.off('connect_error');
          socket.off('disconnect');
          socket.off('process_started');
          socket.off('file_processing_started');
          socket.off('file_processing_read');
          socket.off('file_processing_completed');
          socket.off('file_processing_error');
          socket.off('process_completed');
          socket.off('upload_cancelled');
          socket.off('storing_file');
          socket.off('extracting_relevant_contents');
          socket.off('chunk_processed');
          socket.off('processing_error');
          socket.off('summary_started');
          socket.off('summary_completed');
          socket.off('summary_error');
        };
      } catch (error) {
        console.error('Socket setup error:', error);
        setErrorMessage('Failed to setup socket connection');
      }
    };

    setupSocket();
  }, []);

  useEffect(() => {
    if (totalFiles > 0) {
      setProgressPercentage(Math.floor((processedFiles / totalFiles) * 100));
    }
  }, [processedFiles, totalFiles]);

  const addProgressMessage = (message: string) => {
    setUploadProgress(prev => [...prev, message]);
  };

  const validateFile = (file: File) => {
    const maxSize = 100 * 1024 * 1024; // 100MB
    const allowedTypes = ['application/pdf', 'text/plain', 'application/msword', 
                         'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    
    const validation = {
      valid: true,
      message: 'File is valid'
    };

    if (file.size > maxSize) {
      validation.valid = false;
      validation.message = 'File size exceeds 100MB limit';
    }

    if (!allowedTypes.includes(file.type)) {
      validation.valid = false;
      validation.message = 'Invalid file type. Please upload PDF, TXT, or DOC files';
    }

    return validation;
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const validatedFiles: {[key: string]: { valid: boolean; message: string }} = {};
    
    acceptedFiles.forEach(file => {
      validatedFiles[file.name] = validateFile(file);
    });

    setFileValidation(validatedFiles);
    
    const validFiles = acceptedFiles.filter(file => validatedFiles[file.name].valid);
    setFiles(prevFiles => [...prevFiles, ...validFiles]);
    setTotalFiles(prev => prev + validFiles.length);
    
    validFiles.forEach(file => {
      setFileStatuses(prev => ({ ...prev, [file.name]: 'pending' }));
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!files.length) return;

    setIsUploading(true);
    setShowProgressDialog(true);
    setCurrentStep('document_upload');
    setProcessingStep({
      step: 'uploading',
      progress: 0,
      currentOperation: 'Starting upload...',
      error: undefined,
      currentChunk: 0,
      totalChunks: 0
    });

    const formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    try {
      const socket = await socketService.getSocket();
      if (!socket || !socket.id) {
        throw new Error('Could not establish socket connection');
      }

      formData.append('title', title);
      formData.append('purpose', purpose);

      const baseUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';
      const response = await fetch(`${baseUrl}/upload?sid=${socket.id}`, {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        throw new Error('Upload failed');
      }

      const data = await response.json();
      setUploadId(data.upload_id);
      
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : 'Upload failed');
      setIsUploading(false);
    }
  };

  const removeFile = (index: number) => {
    const fileToRemove = files[index]
    setFiles(prevFiles => prevFiles.filter((_, i) => i !== index))
    setTotalFiles(prev => prev - 1)
    setFileStatuses(prev => {
      const newStatuses = { ...prev }
      delete newStatuses[fileToRemove.name]
      return newStatuses
    })
  }

  const cancelUpload = async () => {
    if (!uploadId) {
      console.log('No upload to cancel');
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/upload/${uploadId}/cancel`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Failed to cancel upload');
      }

      setIsUploading(false);
      setShowProgressDialog(false);
      setUploadProgress(prev => [...prev, 'Upload cancelled by user']);
      
      // Reset states for new upload
      setFiles([]);
      setTotalFiles(0);
      setProcessedFiles(0);
      setProgressPercentage(0);
      setFileStatuses({});
      setUploadId('');
      
    } catch (error) {
      console.error('Error cancelling upload:', error);
      setErrorMessage('Failed to cancel upload');
    }
  };

  const handleFileUpload = async (files: File[]) => {
    // ... existing upload logic ...
    
    // Start the upload process
    setCurrentStep('document_upload');
    setIsUploading(true);
    setShowProgressDialog(true);
    setIsAnalysisComplete(false); // Reset analysis status
    
    // ... rest of the upload logic ...
  };

  // Fetch previous uploads
  useEffect(() => {
    const fetchUploads = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/uploads` || 'http://localhost:5000/api/uploads');
        if (!response.ok) {
          throw new Error('Failed to fetch uploads');
        }
        const data = await response.json();
        console.log('Fetched uploads:', data); // Debug log
        setPreviousUploads(data.uploads || []);
      } catch (error) {
        console.error('Error fetching uploads:', error);
        setErrorMessage('Failed to load previous uploads');
      }
    };
    fetchUploads();
  }, []);

  const handlePreviousUploadSelect = (uploadId: string) => {
    setShowUploadsModal(false);
    navigate(`/options/${uploadId}`);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 to-purple-200">
      <Header 
        chatId=""
        documentMode="off"
        onDocumentModeChange={() => {}}
        onMenuToggle={() => setIsSidebarOpen(!isSidebarOpen)}
      />

      <AIFlowDiagram
        isVisible={showFlowDiagram}
        onToggle={() => setShowFlowDiagram(!showFlowDiagram)}
        isUploadPage={true}
      />

      <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className="w-full max-w-3xl bg-white rounded-xl shadow-xl p-8">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-2xl font-bold text-gray-800">Upload Documents</h2>
            <div className="space-x-4">
              <button
                onClick={() => setShowUploadsModal(true)}
                className="inline-flex items-center gap-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <FileText className="w-4 h-4" />
                Previous Uploads
              </button>
              <button
                onClick={() => navigate('/options')}
                className="inline-flex items-center gap-2 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <FileText className="w-4 h-4" />
                Generate Document
              </button>
            </div>
          </div>

          {/* Upload Area */}
          <div className="space-y-6">
            <div className="mb-6">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-2">
                Project Title
              </label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                required
                placeholder="Enter a descriptive title for your project"
              />
            </div>

            <div className="mb-6">
              <label htmlFor="purpose" className="block text-sm font-medium text-gray-700 mb-2">
                Project Purpose
              </label>
              <textarea
                id="purpose"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300"
                required
                placeholder="Describe the purpose of these documents"
                rows={3}
              />
            </div>

            <div
              {...getRootProps()}
              className={`relative border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-all duration-300 ${
                isDragActive 
                  ? 'border-indigo-500 bg-indigo-50' 
                  : 'border-gray-300 hover:border-indigo-500 hover:bg-indigo-50'
              }`}
            >
              <input {...getInputProps()} />
              <Upload className="mx-auto h-12 w-12 text-gray-400 mb-4" />
              <p className="text-gray-600">
                {isDragActive
                  ? "Drop your files here"
                  : "Drag & drop files here, or click to select"}
              </p>
              <p className="text-sm text-gray-500 mt-2">
                Supported formats: PDF, DOC, DOCX, TXT (Max 100MB)
              </p>
            </div>

            {files.length > 0 && (
              <div className="space-y-4">
                <h3 className="text-lg font-semibold text-gray-700">Selected Files:</h3>
                <div className="space-y-2">
                  {files.map((file, index) => (
                    <div 
                      key={index} 
                      className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-200 transition-all duration-300 hover:bg-gray-100"
                    >
                      <div className="flex items-center space-x-3">
                        <File className="h-5 w-5 text-gray-400" />
                        <div>
                          <p className="text-sm font-medium text-gray-700">{file.name}</p>
                          <p className="text-xs text-gray-500">
                            {(file.size / 1024 / 1024).toFixed(2)} MB
                          </p>
                        </div>
                        {fileValidation[file.name]?.valid === false && (
                          <Badge variant="destructive" className="ml-2">
                            {fileValidation[file.name].message}
                          </Badge>
                        )}
                      </div>
                      <div className="flex items-center space-x-2">
                        {fileStatuses[file.name] === 'completed' && (
                          <CheckCircle className="h-5 w-5 text-green-500" />
                        )}
                        {fileStatuses[file.name] === 'processing' && (
                          <Loader className="h-5 w-5 text-blue-500 animate-spin" />
                        )}
                        {fileStatuses[file.name] === 'error' && (
                          <AlertCircle className="h-5 w-5 text-red-500" />
                        )}
                        <button
                          onClick={() => removeFile(index)}
                          className="p-1 hover:bg-gray-200 rounded-full transition-colors"
                          disabled={fileStatuses[file.name] === 'processing'}
                        >
                          <X className="h-5 w-5 text-gray-500" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <button
              type="submit"
              onClick={handleSubmit}
              disabled={isSubmitting || files.length === 0 || !title || !purpose}
              className={`w-full bg-indigo-600 text-white py-3 px-6 rounded-lg font-semibold text-lg hover:bg-indigo-700 transition-all duration-300 flex items-center justify-center gap-2 ${
                (isSubmitting || files.length === 0 || !title || !purpose) 
                  ? 'opacity-50 cursor-not-allowed' 
                  : ''
              }`}
            >
              {isSubmitting ? (
                <>
                  <Loader className="h-5 w-5 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  <Upload className="h-5 w-5" />
                  Upload Files
                </>
              )}
            </button>
          </div>

          {/* Previous Uploads Modal */}
          <Dialog open={showUploadsModal} onOpenChange={setShowUploadsModal}>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Previous Uploads</DialogTitle>
              </DialogHeader>
              <div className="mt-4 max-h-[60vh] overflow-y-auto">
                {previousUploads.length > 0 ? (
                  <div className="space-y-3">
                    {previousUploads.map((upload) => (
                      <div
                        key={upload.id}
                        onClick={() => handlePreviousUploadSelect(upload.id)}
                        className="p-4 border rounded-lg hover:bg-gray-50 cursor-pointer transition-colors duration-200"
                      >
                        <div className="flex items-center gap-3">
                          <FileText className="w-5 h-5 text-blue-500" />
                          <div>
                            <div className="font-medium">{upload.file_name}</div>
                            <div className="text-sm text-gray-500">
                              Uploaded: {new Date(upload.upload_date).toLocaleDateString()}
                            </div>
                            <div className="text-sm text-gray-500">
                              Purpose: {upload.purpose}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="text-center py-8 text-gray-500">
                    <FileText className="w-12 h-12 mx-auto mb-3 text-gray-400" />
                    <p>No previous uploads found</p>
                  </div>
                )}
              </div>
            </DialogContent>
          </Dialog>

          {/* Progress Dialog */}
          {showProgressDialog && (
            <Dialog open={showProgressDialog} onOpenChange={setShowProgressDialog}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Upload Progress</DialogTitle>
                </DialogHeader>
                <div className="mt-4">
                  <Progress value={processingStep.progress} className="w-full" />
                  <div className="space-y-2 mt-2">
                    <p className="text-sm font-medium text-gray-700">
                      {processingStep.currentOperation} ({processingStep.progress}%)
                    </p>
                    {processingStep.error && (
                      <div className="mt-2 p-2 bg-red-50 rounded">
                        <p className="text-sm font-medium text-red-800">Error:</p>
                        <p className="text-sm text-red-600">
                          {processingStep.error}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <ScrollArea className="h-[200px] w-full rounded-md border p-4 mt-4 bg-gray-50">
                  <div className="space-y-2">
                    {uploadProgress.map((message, index) => (
                      <div 
                        key={index} 
                        className={`text-sm p-2 rounded ${
                          message.includes('Error') 
                            ? 'text-red-600 bg-red-50' 
                            : message.includes('Completed') 
                              ? 'text-green-600 bg-green-50'
                              : 'text-gray-600 bg-white'
                        }`}
                      >
                        {message}
                      </div>
                    ))}
                  </div>
                </ScrollArea>
                <div className="flex justify-end space-x-2 mt-4">
                  <button
                    onClick={cancelUpload}
                    disabled={!isUploading}
                    className={`px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 ${
                      !isUploading ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    Cancel Upload
                  </button>
                  <button
                    onClick={() => setShowProgressDialog(false)}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Close
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          )}

          {/* Markdown Modal */}
          {showMarkdownModal && (
            <MarkdownModal
              content={markdownContent}
              title="Upload Progress"
              isOpen={showMarkdownModal}
              onClose={() => setShowMarkdownModal(false)}
              generationCompleted={processingStep.step === 'completed'}
              isGenerationError={!!processingStep.error}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default FileUploadForm

