import React, { useEffect, useState } from 'react';
import { Socket } from 'socket.io-client';

interface DocumentContentManagerProps {
  socket: Socket | null;
  book_title: string;
  chapters: string[];
  onContentUpdate: (content: string) => void;
  onGenerationComplete: () => void;
  onError: (error: string) => void;
}

export const DocumentContentManager: React.FC<DocumentContentManagerProps> = ({
  socket,
  book_title,
  chapters,
  onContentUpdate,
  onGenerationComplete,
  onError,
}) => {
  const [accumulatedContent, setAccumulatedContent] = useState<{ [key: number]: string }>({});
  const [processedChapters, setProcessedChapters] = useState<number[]>([]);
  const [isGenerationDone, setIsGenerationDone] = useState(false);

  useEffect(() => {
    if (!socket) return;

    const handleGenerationStarted = (data: any) => {
      console.log("Generation started:", data);
      setAccumulatedContent({});
      setProcessedChapters([]);
      setIsGenerationDone(false);
      const initialContent = generateInitialContent(book_title);
      onContentUpdate(initialContent);
    };

    const handleChapterDelta = (data: { chapter_number: number; chapter_text: string }) => {
      console.log("Chapter update received:", data);
      
      // Format the chapter content - ensure headers use ###
      const formattedChapter = data.chapter_text
        .replace(/^#+\s+/gm, '### ')  // Convert any number of # to ###
        .replace(/^(\d+\.)\s+/gm, '### ') // Convert numbered sections to ###
        .replace(/^\*\*(.*?)\*\*$/gm, '### $1') // Convert **Title** to ### Title
        .replace(/^([A-Z][A-Za-z\s]+):$/gm, '### $1'); // Convert Title: to ### Title
      
      console.log("Formatted chapter:", formattedChapter);
      
      // Update accumulated content
      setAccumulatedContent(prev => ({
        ...prev,
        [data.chapter_number]: formattedChapter
      }));

      // Add to processed chapters if not already included
      if (!processedChapters.includes(data.chapter_number)) {
        setProcessedChapters(prev => [...prev, data.chapter_number].sort((a, b) => a - b));
      }

      // Force content update
      const updatedContent = generateFullContent(
        book_title,
        chapters,
        { ...accumulatedContent, [data.chapter_number]: formattedChapter },
        [...processedChapters, data.chapter_number]
      );
      onContentUpdate(updatedContent);
    };

    const handleGenerationCompleted = () => {
      setIsGenerationDone(true);
      onGenerationComplete();
    };

    const handleError = (data: { error: string }) => {
      setIsGenerationDone(true);
      onError(data.error);
    };

    // Set up socket listeners
    socket.on("generation_started", handleGenerationStarted);
    socket.on("chapter_delta", handleChapterDelta);
    socket.on("generation_completed", handleGenerationCompleted);
    socket.on("generation_error", handleError);

    // Clean up listeners
    return () => {
      socket.off("generation_started", handleGenerationStarted);
      socket.off("chapter_delta", handleChapterDelta);
      socket.off("generation_completed", handleGenerationCompleted);
      socket.off("generation_error", handleError);
    };
  }, [socket, book_title, chapters, onContentUpdate, onGenerationComplete, onError]);

  // Update content whenever accumulated content changes
  useEffect(() => {
    if (Object.keys(accumulatedContent).length === 0) return;

    const fullContent = generateFullContent(book_title, chapters, accumulatedContent, processedChapters);
    onContentUpdate(fullContent);
  }, [accumulatedContent, processedChapters, book_title, chapters, onContentUpdate]);

  return null; // This is a logic-only component
};

// Helper functions
function generateInitialContent(title: string): string {
  return `# ${title}\n\n`;
}

function generateFullContent(
  title: string,
  chapters: string[],
  accumulatedContent: { [key: number]: string },
  processedChapters: number[]
): string {
  const initial = generateInitialContent(title);
  
  const chaptersContent = processedChapters
    .map(chapterNum => {
      const chapterContent = accumulatedContent[chapterNum];
      return chapterContent;
    })
    .join('\n\n');

  const footer = '\n\n\n\n\n\n\n---\n' +
    '_Generated by Text Miner AI_\n' +
    `_${new Date().toLocaleDateString()}_`;

  return initial + chaptersContent + footer;
}

export default DocumentContentManager; 