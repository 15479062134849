import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FileText, AlertCircle, Loader, Sparkles, Brain, Zap, CheckCircle2} from 'lucide-react';
import GeneratedOutlineModal from "../components/GeneratedOutlineModal";
import TypeformLikeQuestions from "../components/TypeFromLikeQuestions";
import MarkdownModal from '../components/MarkDownModal';
import SystemPromptTerminal from '../components/SystemPromptTerminal';
import { getPromptInfo } from '../services/systemPrompts';
import {  Socket } from 'socket.io-client';
import socketService from '../services/socketService';
import AIFlowDiagram from '../components/AIFlowDiagram';
import { useGenerationStore } from "../store/generationSteps";
import { FiMenu, FiHome, FiMessageSquare, FiUpload, FiChevronDown } from 'react-icons/fi';
import DocumentContentManager from '../components/DocumentContentManager';
import { Progress } from '../components/progress';
import { motion } from 'framer-motion';

interface UploadData {
  unique_id: string;
  title: string | null;
  summary: string | null;
  file_name_list: string[] | null;
  created_at: string | null;
}

interface Question {
  id: string;
  text: string;
}

interface BestPracticeGenerationForm {
  questions: Question[];
}

interface Section {
  title: string;
  description: string;
  metrics: string;
}

export default function FileGenerationForm() {
  const { uploadId } = useParams<{ uploadId: string }>();
  const navigate = useNavigate();
  const [description, setDescription] = useState("");
  const [isOutlineGenerating, setIsOutlineGenerating] = useState(false);
  const [uploadData, setUploadData] = useState<UploadData | null>(null);
  const [showOutlineModal, setShowOutlineModal] = useState(false);
  const [chapters, setChapters] = useState<string[]>([]);
  const [sections, setSections] = useState<Section[]>([]);
  const [book_title, setBookTitle] = useState("");
  const [form, setForm] = useState<BestPracticeGenerationForm | null>(null);
  const [showQuestions, setShowQuestions] = useState(false);
  const [formAnswers, setFormAnswers] = useState<Record<string, string>>({});
  const [isGenerationError, setIsGenerationError] = useState(false);
  const [generationCompleted, setGenerationCompleted] = useState(false);
  const [skt, setSkt] = useState<Socket | null>(null);
  const [isMarkdownModalOpen, setIsMarkdownModalOpen] = useState(false);
  const [markdownContent, setMarkdownContent] = useState("");
  const [promptMode, setPromptMode] = useState<'guided' | 'custom'>('guided');
  const [customPrompt, setCustomPrompt] = useState<string>('');
  const [isOptimizing, setIsOptimizing] = useState(false);
  const [optimizedPrompt, setOptimizedPrompt] = useState<{
    optimized_prompt: string;
    explanation: string;
    suggestions: string[];
  } | null>(null);

  const [optimizeError, setOptimizeError] = useState<string | null>(null);

  const [pageCount, setPageCount] = useState("5");

  const promptInfo = getPromptInfo('generate');

  const [isGuidedQuestionsLoading, setIsGuidedQuestionsLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [isGeneratingDocument, setIsGeneratingDocument] = useState(false);

  const [showFlowDiagram, setShowFlowDiagram] = useState(false);

  const { setCurrentStep, completeStep, isStepCompleted, setProcessing } = useGenerationStore();

  const [showNavDropdown, setShowNavDropdown] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const [hasReceivedFirstContent, setHasReceivedFirstContent] = useState(false);

  const handleContentUpdate = (content: string) => {
    console.log("Content update received:", content);
    setMarkdownContent(content);
    setIsMarkdownModalOpen(true);
    if (!hasReceivedFirstContent) {
      setHasReceivedFirstContent(true);
      setShowOutlineModal(false);
    }
    
    // If content includes completion marker, set generation as completed
    if (content.includes("### ✅ Generation Complete")) {
      setGenerationCompleted(true);
      setIsGeneratingDocument(false);
      setProcessing(false);
      completeStep('document_generation');
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTop = contentRef.current.scrollHeight;
    }
  }, [markdownContent]);

  useEffect(() => {
    // Set initial state - document_upload and document_analysis are completed
    if (isStepCompleted('document_analysis')) {
      setCurrentStep('user_input');
    }
  }, []);

  useEffect(() => {
    const setupSocket = async () => {
      try {
        const socket = await socketService.getSocket();
        if (!socket) {
          setErrorMessage('Could not establish socket connection');
          setIsGenerationError(true);
          return;
        }
        
        setSkt(socket);

        socket.on('generation_completed', () => {
          setIsGeneratingDocument(false);
          setGenerationCompleted(true);
          setProcessing(false);
          completeStep('document_generation');
        });

        socket.on('generation_error', (data: { error: string }) => {
          setIsGeneratingDocument(false);
          setIsGenerationError(true);
          setProcessing(false);
          setMarkdownContent(prev => 
            prev + "\n### ❌ Error\n" + 
            `${data.error}\n`
          );
        });

        return () => {
          socket.off('generation_completed');
          socket.off('generation_error');
        };
        
      } catch (error) {
        console.error("Socket setup error:", error);
        setIsGenerationError(true);
        setErrorMessage(error instanceof Error ? error.message : 'Failed to setup socket connection');
      }
    };

    setupSocket();
  }, []);

  useEffect(() => {
    const fetchUploadData = async () => {
      if (uploadId) {
        try {
          setIsGuidedQuestionsLoading(true);
          const response = await fetch(`${window.env.REACT_APP_BACKEND_URL}/generate/form/${uploadId}`);
          if (response.ok) {
            const result = await response.json();
            setUploadData(result.upload);
            setForm({
              questions: [
                ...result.form.map((question: string, index: number) => ({
                  id: `q${index + 1}`,
                  text: question
                })),
                {
                  id: 'qAdditional',
                  text: 'Is there anything else we should consider?'
                },
                {
                  id: 'qPages',
                  text: 'How many number of pages approximately should the file have?'
                }
              ]
            });
        
            if (promptMode === 'guided') {
              setShowQuestions(true);
            }
          } else {
            console.error("Failed to fetch upload data");
          
          }
        } catch (error) {
          console.error("Error fetching upload data:", error);
          
        } finally {
          setIsGuidedQuestionsLoading(false);
        }
      }
    };

    fetchUploadData();
  }, [uploadId]);

  useEffect(() => {
    if (chapters && chapters.length > 0) {
      try {
        const content = chapters[0];
        if (typeof content !== 'string') {
          throw new Error('Invalid content format');
        }

        // Split content into lines and process headers
        const lines = content.split('\n');
        const sectionsArray: Section[] = [];
        let currentSection: Section | null = null;
        let currentContent: string[] = [];

        for (let line of lines) {
          // Check for header (starts with # at any level)
          if (line.match(/^#{1,6}\s/)) {
            // If we have a current section, save it
            if (currentSection) {
              // Process the content to create proper bullet points and line breaks
              const cleanContent = currentContent
                .join('\n')
                .replace(/\*\*/g, '')  // Remove bold markers
                .split('\n')
                .map(line => {
                  // Clean up bullet points and add proper spacing
                  line = line.trim();
                  if (line.startsWith('-')) {
                    // Split bullet point content if it contains multiple parts
                    const parts = line.substring(2).split('.')
                      .map(part => part.trim())
                      .filter(part => part);
                    return '• ' + parts.join('\n  ');
                  }
                  return line;
                })
                .filter(line => line)  // Remove empty lines
                .join('\n');

              currentSection.description = cleanContent;
              sectionsArray.push(currentSection);
            }
            // Start new section
            currentSection = {
              title: line.replace(/^#+\s*/, '').trim().replace(/\*\*/g, ''),
              description: '',
              metrics: ''
            };
            currentContent = [];
          } else if (currentSection && line.trim()) {
            currentContent.push(line);
          }
        }

        // Don't forget to add the last section
        if (currentSection) {
          const cleanContent = currentContent
            .join('\n')
            .replace(/\*\*/g, '')
            .split('\n')
            .map(line => {
              line = line.trim();
              if (line.startsWith('-')) {
                const parts = line.substring(2).split('.')
                  .map(part => part.trim())
                  .filter(part => part);
                return '• ' + parts.join('\n  ');
              }
              return line;
            })
            .filter(line => line)
            .join('\n');

          currentSection.description = cleanContent;
          sectionsArray.push(currentSection);
        }

        setSections(sectionsArray);
      } catch (error) {
        console.error('Error parsing sections:', error);
        setSections([]);
      }
    }
  }, [chapters]);

  const handleGenerateOutline = async (e: React.FormEvent) => {
    e.preventDefault();
    const trimmedPrompt = customPrompt?.trim() || '';
    if (promptMode === 'custom' && !trimmedPrompt) return;

    // Clear previous content
    setChapters([]);
    setSections([]);
    setMarkdownContent("");
    
    setIsOutlineGenerating(true);
    completeStep('user_input');
    setProcessing(true);

    try {
      const outlineResponse = await fetch(`${window.env.REACT_APP_BACKEND_URL}/generate/outline/${uploadId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          form_answers: formAnswers,
          custom_prompt: trimmedPrompt || "Summarize the key concepts of the document, excluding any information related to startups.",
          page_count: pageCount
        })
      });

      if (!outlineResponse.ok) {
        throw new Error('Failed to generate outline');
      }

      const result = await outlineResponse.json();
      
      // The response content is directly in result.outline.content.content
      if (result.outline?.content?.content) {
        setChapters([result.outline.content.content]);
        setBookTitle("Generated Content");
        setShowOutlineModal(true);
        completeStep('outline_generation');
      } else {
        throw new Error('Invalid outline format in response');
      }
    } catch (error) {
      console.error("Error generating outline:", error);
      setIsGenerationError(true);
    } finally {
      setIsOutlineGenerating(false);
      setProcessing(false);
    }
  };


  const handleGenerateDocument = async () => {
    if (isGeneratingDocument) return;
    setIsGeneratingDocument(true);
    setIsMarkdownModalOpen(true);
    setCurrentStep('document_generation');
    setProcessing(true);
    
    try {
      const socket = await socketService.getSocket();
      if (!socket) {
        throw new Error('No socket connection available');
      }

      setMarkdownContent("");
      setGenerationCompleted(false);
      setIsGenerationError(false);

      // Convert sections to a simpler format that's easier for the backend to handle
      const chaptersFormatted = sections.map(section => ({
        title: section.title,
        content: `${section.description}\n\nKey Metrics:\n${section.metrics}`
      }));

      const requestData = {
        book_title: book_title || "Generated Document",
        chapters_list: chaptersFormatted,
        socket_id: socket.id,
        section_prompt: promptMode === 'custom' ? customPrompt : formAnswers['What kind of information would you like to extract from the document?'],
        outline_overview: chapters[0],
        maintain_thread: true
      };
      
      const response = await fetch(
        `${window.env.REACT_APP_BACKEND_URL}/generate/doc/${uploadId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error('Document generation failed');
      }

      // We'll close the outline modal when we receive the first content update
      // The modal will be closed in the DocumentContentManager component
      // setShowOutlineModal(false);  // Remove this line
    } catch (error) {
      console.error("Error during document generation:", error);
      setIsGenerationError(true);
      setMarkdownContent(prev => 
        prev + "\n### ❌ Error\n" + 
        `Failed to start generation: ${error instanceof Error ? error.message : 'Unknown error'}\n`
      );
      setProcessing(false);
    } finally {
      setIsGeneratingDocument(false);
    }
  };

  
  const handleFormComplete = (answers: Record<string, string>) => {
    const formattedAnswers = Object.entries(answers).reduce((acc, [questionId, answer]) => {
      const question = form?.questions.find(q => q.id === questionId)?.text || '';
      
      if (questionId === 'qPages') {
        setPageCount(answer);
      } else {
        acc[question] = answer;
      }
      
      return acc;
    }, {} as Record<string, string>);

    setFormAnswers(formattedAnswers);
    setShowQuestions(false);
  };

  const handleOptimizePrompt = async () => {
    if (!customPrompt || typeof customPrompt !== 'string') return;
    
    const trimmedPrompt = customPrompt.trim();
    if (!trimmedPrompt) return;
    
    setIsOptimizing(true);
    setOptimizeError(null);
    try {
      const response = await fetch(`${window.env.REACT_APP_BACKEND_URL}/generate/optimize-prompt`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          prompt: trimmedPrompt,
          upload_id: uploadId
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to optimize prompt');
      }
      
      const data = await response.json();
      setOptimizedPrompt(data);
      setCustomPrompt(data.optimized_prompt);
    } catch (error) {
      console.error('Error optimizing prompt:', error);
      setOptimizeError(error instanceof Error ? error.message : 'Failed to optimize prompt');
    } finally {
      setIsOptimizing(false);
    }
  };

  const handleCloseQuestions = () => {
    setShowQuestions(false);
    setFormAnswers({});
  };

  const handleGenerationComplete = () => {
    setIsGeneratingDocument(false);
    setGenerationCompleted(true);
    setProcessing(false);
    setIsMarkdownModalOpen(true);
    completeStep('document_generation');
  };

  const handleGenerationError = (error: string) => {
    setIsGeneratingDocument(false);
    setIsGenerationError(true);
    setProcessing(false);
    setMarkdownContent(prev => 
      prev + "\n### ❌ Error\n" + 
      `${error}\n`
    );
  };

  const handleDeleteOutline = async () => {
    // Clear all content
    setChapters([]);
    setSections([]);
    setMarkdownContent("");
    
    // Reset all states
    setShowOutlineModal(false);
    setIsMarkdownModalOpen(false);
    setIsGenerationError(false);
    setGenerationCompleted(false);
    setIsGeneratingDocument(false);
    setHasReceivedFirstContent(false);
    
    // Clear form answers if in guided mode
    if (promptMode === 'guided') {
      setFormAnswers({});
      setShowQuestions(true);
    }

    // Disconnect and clear socket
    if (skt) {
      skt.disconnect();
      setSkt(null);
      // Reconnect socket for future use
      const newSocket = await socketService.getSocket();
      setSkt(newSocket);
    }

    // Force reload the page to clear all states
    window.location.reload();
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-100 to-purple-200">
      <div className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-4">
            <div className="flex items-center">
              <h1 
                onClick={() => navigate('/')}
                className="text-xl font-semibold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent cursor-pointer hover:opacity-80 transition-opacity"
              >
                Text Miner
              </h1>
            </div>

            <div className="relative">
              <button
                onClick={() => setShowNavDropdown(!showNavDropdown)}
                className="flex items-center gap-2 px-4 py-2 bg-white border border-gray-200 text-gray-700 rounded-lg hover:bg-gray-50 hover:border-indigo-200 transition-all duration-300 group shadow-sm"
              >
                <FiMenu className="w-5 h-5 text-gray-600 group-hover:text-indigo-600" />
                <span className="hidden md:inline">Navigate</span>
                <FiChevronDown 
                  className={`w-4 h-4 text-gray-600 group-hover:text-indigo-600 transition-transform duration-300 ${
                    showNavDropdown ? 'rotate-180' : ''
                  }`} 
                />
              </button>

              {showNavDropdown && (
                <div 
                  className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-xl border border-gray-100 overflow-hidden z-50 transform origin-top scale-100 transition-all duration-200"
                  onMouseLeave={() => setShowNavDropdown(false)}
                >
                  <div className="py-2">
                    <button
                      onClick={() => navigate('/')}
                      className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gradient-to-r hover:from-indigo-50 hover:to-purple-50 flex items-center gap-2 group"
                    >
                      <FiHome className="w-4 h-4 text-gray-400 group-hover:text-indigo-600" />
                      <span>Home</span>
                    </button>
                    <button
                      onClick={() => navigate(`/chat/${uploadId}`)}
                      className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gradient-to-r hover:from-indigo-50 hover:to-purple-50 flex items-center gap-2 group"
                    >
                      <FiMessageSquare className="w-4 h-4 text-gray-400 group-hover:text-indigo-600" />
                      <span>Chat</span>
                    </button>
                    <button
                      onClick={() => navigate('/')}
                      className="w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gradient-to-r hover:from-indigo-50 hover:to-purple-50 flex items-center gap-2 group"
                    >
                      <FiUpload className="w-4 h-4 text-gray-400 group-hover:text-indigo-600" />
                      <span>New Upload</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <AIFlowDiagram
        isVisible={showFlowDiagram}
        onToggle={() => setShowFlowDiagram(!showFlowDiagram)}
        isUploadPage={false}
      />

      <div className="flex items-center justify-center px-4 sm:px-6 lg:px-8 py-12">
        <div className="w-full max-w-3xl bg-white rounded-xl shadow-xl p-8">
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-4">Generate Document</h2>
            
            <div className="mb-6">
              <div className="flex items-center justify-between mb-4">
                <label className="text-lg font-medium text-gray-700">Generation Method</label>
                <div className="flex items-center space-x-4">
                  <button
                    onClick={() => {
                      setPromptMode('guided');
                      setShowQuestions(true);
                    }}
                    disabled={isGuidedQuestionsLoading}
                    className={`px-4 py-2 rounded-lg transition-colors flex items-center gap-2 ${
                      promptMode === 'guided'
                        ? 'bg-indigo-600 text-white' 
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    } ${isGuidedQuestionsLoading ? 'opacity-75 cursor-wait' : ''}`}
                  >
                    {isGuidedQuestionsLoading ? (
                      <>
                        <Loader className="w-4 h-4 animate-spin" />
                        Loading...
                      </>
                    ) : (
                      'Guided Questions'
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setPromptMode('custom');
                      setShowQuestions(false);
                    }}
                    className={`px-4 py-2 rounded-lg transition-colors ${
                      promptMode === 'custom'
                        ? 'bg-indigo-600 text-white' 
                        : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    Custom Prompt
                  </button>
                </div>
              </div>
            </div>

            {promptMode === 'custom' ? (
              <div className="mb-6 space-y-6">
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <label className="block text-sm font-medium text-gray-700">
                      Custom Generation Prompt
                    </label>
                    <button
                      onClick={handleOptimizePrompt}
                      disabled={!customPrompt.trim() || isOptimizing}
                      className="text-sm px-3 py-1 bg-indigo-100 text-indigo-700 rounded-md hover:bg-indigo-200 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                    >
                      {isOptimizing ? (
                        <>
                          <Loader className="w-4 h-4 animate-spin" />
                          Optimizing...
                        </>
                      ) : (
                        <>
                          <span className="w-4 h-4">✨</span>
                          Optimize Prompt
                        </>
                      )}
                    </button>
                  </div>
                  <textarea
                    value={customPrompt}
                    onChange={(e) => {
                      setCustomPrompt(e.target.value);
                      setOptimizedPrompt(null);
                    }}
                    className="w-full h-48 p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                    placeholder="Enter your custom prompt here. Describe what kind of document you want to generate from your uploaded content..."
                  />
                  
                  {/* Error Message */}
                  {optimizeError && (
                    <div className="mt-2 p-2 bg-red-50 border border-red-200 rounded-md">
                      <p className="text-sm text-red-600 flex items-center gap-2">
                        <AlertCircle className="w-4 h-4" />
                        {optimizeError}
                      </p>
                    </div>
                  )}
                  
                  {/* Optimization Results */}
                  {optimizedPrompt && (
                    <div className="mt-4 p-4 bg-indigo-50 rounded-lg space-y-3">
                      <div>
                        <h4 className="text-sm font-medium text-indigo-900">Improvements Made:</h4>
                        <p className="text-sm text-indigo-700">{optimizedPrompt.explanation}</p>
                      </div>
                      {optimizedPrompt.suggestions.length > 0 && (
                        <div>
                          <h4 className="text-sm font-medium text-indigo-900">Additional Suggestions:</h4>
                          <ul className="list-disc list-inside text-sm text-indigo-700">
                            {optimizedPrompt.suggestions.map((suggestion, index) => (
                              <li key={index}>{suggestion}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="space-y-4 mt-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Approximate Page Count
                      </label>
                      <input
                        type="number"
                        min="1"
                        value={pageCount}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPageCount(newValue);
                          setFormAnswers(prev => ({ ...prev, qPages: newValue }));
                        }}
                        className="w-full p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent"
                      />
                    </div>
                    <button
                  onClick={handleGenerateOutline}
                  className="w-full bg-indigo-600 text-white py-3 px-6 rounded-lg font-semibold text-lg hover:bg-indigo-700 transition-all duration-300 flex items-center justify-center gap-2"
                  disabled={promptMode === 'custom' ? !customPrompt?.trim() || isOutlineGenerating : isOutlineGenerating}
                >
                  {isOutlineGenerating ? (
                    <>
                      <Loader className="w-5 h-5 animate-spin" />
                      Generating Outline...
                    </>
                  ) : (
                    <>
                      <FileText className="w-5 h-5" />
                      Generate Outline
                    </>
                  )}
                </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="relative">
                {showQuestions && form ? (
                  <TypeformLikeQuestions
                    questions={form.questions}
                    onComplete={handleFormComplete}
                    onClose={handleCloseQuestions}
                  />
                ) : (
                  <div className="text-center space-y-4">
                    {Object.keys(formAnswers).length > 0 ? (
                    <button
                      onClick={handleGenerateOutline}
                      className="w-full bg-indigo-600 text-white py-3 px-6 rounded-lg font-semibold text-lg hover:bg-indigo-700 transition-all duration-300 flex items-center justify-center gap-2"
                        disabled={isOutlineGenerating}
                    >
                      {isOutlineGenerating ? (
                        <>
                          <Loader className="w-5 h-5 animate-spin" />
                          Generating Outline...
                        </>
                      ) : (
                        <>
                          <FileText className="w-5 h-5" />
                          Generate Outline
                        </>
                      )}
                    </button>
                    ) : (
                      <button
                        onClick={() => setShowQuestions(true)}
                        className="w-full py-3 px-6 bg-indigo-600 text-white rounded-lg font-semibold hover:bg-indigo-700 transition-colors"
                      >
                        Start Guided Questions
                      </button>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          {showOutlineModal && (
            <GeneratedOutlineModal
              title={book_title}
              description={description}
              chapters={chapters}
              isGenerating={isOutlineGenerating}
              isGeneratingDocument={isGeneratingDocument}
              onSetBookTitle={setBookTitle}
              onSetChapters={setChapters}
              onSetDescription={setDescription}
              onClose={() => setShowOutlineModal(false)}
              onApprove={handleGenerateDocument}
              onDelete={handleDeleteOutline}
            />
          )}

          {isMarkdownModalOpen && (
            <MarkdownModal
              content={markdownContent}
              title={book_title}
              isOpen={isMarkdownModalOpen}
              onClose={() => setIsMarkdownModalOpen(false)}
              generationCompleted={generationCompleted}
              isGenerationError={isGenerationError}
              onDelete={handleDeleteOutline}
            />
          )}

          {errorMessage && (
            <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-md">
              <p className="text-sm text-red-600 flex items-center gap-2">
                <AlertCircle className="w-4 h-4" />
                {errorMessage}
              </p>
            </div>
          )}
        </div>
      </div>
      <SystemPromptTerminal 
        systemPrompt={promptInfo.systemPrompt} 
        relatedPrompts={promptInfo.relatedPrompts}
      />

      {/* Document Content Manager */}
      <DocumentContentManager
        socket={skt}
        book_title={book_title}
        chapters={chapters}
        onContentUpdate={handleContentUpdate}
        onGenerationComplete={handleGenerationComplete}
        onError={handleGenerationError}
      />

      {isOutlineGenerating && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="fixed inset-0 bg-gradient-to-br from-black/80 via-gray-900/80 to-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4 overflow-y-auto"
        >
          <motion.div 
            initial={{ scale: 0.9, y: 20 }}
            animate={{ scale: 1, y: 0 }}
            className="bg-gradient-to-b from-white via-gray-50 to-white dark:from-gray-800 dark:via-gray-850 dark:to-gray-800 p-8 md:p-10 rounded-2xl shadow-2xl w-full max-w-4xl mx-auto my-8"
          >
            <Progress 
              value={50} 
              status="processing"
              className="mb-6"
              title="🤖 AI Outline Generation"
              description="Our AI is analyzing your requirements and crafting a personalized document outline..."
            />
            <div className="mt-8 space-y-4 bg-white/50 dark:bg-gray-800/50 p-6 rounded-xl backdrop-blur-sm border border-white/20 dark:border-gray-700/30">
              <div className="flex items-center gap-3 text-sm text-gray-700 dark:text-gray-200">
                <div className="p-2 rounded-lg bg-purple-100 dark:bg-purple-900/30">
                  <Sparkles className="w-5 h-5 text-purple-600 dark:text-purple-400" />
                </div>
                <span>Analyzing content requirements and context</span>
              </div>
              <div className="flex items-center gap-3 text-sm text-gray-700 dark:text-gray-200">
                <div className="p-2 rounded-lg bg-indigo-100 dark:bg-indigo-900/30">
                  <Brain className="w-5 h-5 text-indigo-600 dark:text-indigo-400" />
                </div>
                <span>Structuring document sections with AI insights</span>
              </div>
              <div className="flex items-center gap-3 text-sm text-gray-700 dark:text-gray-200">
                <div className="p-2 rounded-lg bg-blue-100 dark:bg-blue-900/30">
                  <FileText className="w-5 h-5 text-blue-600 dark:text-blue-400" />
                </div>
                <span>Preparing comprehensive chapter outlines</span>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}

      {isGeneratingDocument && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="fixed inset-0 bg-gradient-to-br from-black/80 via-gray-900/80 to-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4 overflow-y-auto"
        >
          <motion.div 
            initial={{ scale: 0.9, y: 20 }}
            animate={{ scale: 1, y: 0 }}
            className="bg-gradient-to-b from-white via-gray-50 to-white dark:from-gray-800 dark:via-gray-850 dark:to-gray-800 p-8 md:p-10 rounded-2xl shadow-2xl w-full max-w-4xl mx-auto my-8"
          >
            <Progress 
              value={75} 
              status="processing"
              className="mb-6"
              title="✨ AI Document Generation"
              description="Our advanced AI is crafting your document with detailed insights and professional content..."
            />
            <div className="mt-8 space-y-4 bg-white/50 dark:bg-gray-800/50 p-6 rounded-xl backdrop-blur-sm border border-white/20 dark:border-gray-700/30">
              <div className="flex items-center gap-3 text-sm text-gray-700 dark:text-gray-200">
                <div className="p-2 rounded-lg bg-amber-100 dark:bg-amber-900/30">
                  <Zap className="w-5 h-5 text-amber-600 dark:text-amber-400" />
                </div>
                <span>Processing document structure and layout</span>
              </div>
              <div className="flex items-center gap-3 text-sm text-gray-700 dark:text-gray-200">
                <div className="p-2 rounded-lg bg-purple-100 dark:bg-purple-900/30">
                  <Brain className="w-5 h-5 text-purple-600 dark:text-purple-400" />
                </div>
                <span>Generating intelligent content with AI</span>
              </div>
              <div className="flex items-center gap-3 text-sm text-gray-700 dark:text-gray-200">
                <div className="p-2 rounded-lg bg-green-100 dark:bg-green-900/30">
                  <CheckCircle2 className="w-5 h-5 text-green-600 dark:text-green-400" />
                </div>
                <span>Applying professional formatting standards</span>
              </div>
            </div>

            <div 
              className="mt-6 max-h-[300px] overflow-y-auto rounded-lg bg-gray-50 dark:bg-gray-900/50 p-4 font-mono text-sm text-gray-600 dark:text-gray-300 border border-gray-200 dark:border-gray-700"
              style={{ scrollBehavior: 'smooth' }}
              ref={contentRef}
            >
              {markdownContent.split('\n').map((line, index) => (
                <div key={index} className="py-0.5">
                  {line || '\u00A0'}
                </div>
              ))}
            </div>
          </motion.div>
        </motion.div>
      )}

      {isGenerationError && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="fixed inset-0 bg-gradient-to-br from-black/80 via-gray-900/80 to-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4 overflow-y-auto"
        >
          <motion.div 
            initial={{ scale: 0.9, y: 20 }}
            animate={{ scale: 1, y: 0 }}
            className="bg-gradient-to-b from-white via-gray-50 to-white dark:from-gray-800 dark:via-gray-850 dark:to-gray-800 p-8 md:p-10 rounded-2xl shadow-2xl w-full max-w-4xl mx-auto my-8"
          >
            <Progress 
              value={100} 
              status="error"
              className="mb-6"
              title="Generation Error"
              description={errorMessage || "An error occurred during the generation process. Please try again."}
            />
            <div className="mt-8 flex justify-end gap-3">
              <button
                onClick={() => setIsGenerationError(false)}
                className="px-5 py-2.5 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600 transition-all duration-200"
              >
                Close
              </button>
              <button
                onClick={handleGenerateDocument}
                className="px-5 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700 rounded-lg transition-all duration-200 shadow-lg hover:shadow-indigo-500/25"
              >
                Try Again
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}

      {generationCompleted && (
        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="fixed inset-0 bg-gradient-to-br from-black/80 via-gray-900/80 to-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-4 overflow-y-auto"
        >
          <motion.div 
            initial={{ scale: 0.9, y: 20 }}
            animate={{ scale: 1, y: 0 }}
            className="bg-gradient-to-b from-white via-gray-50 to-white dark:from-gray-800 dark:via-gray-850 dark:to-gray-800 p-8 md:p-10 rounded-2xl shadow-2xl w-full max-w-4xl mx-auto my-8"
          >
            <Progress 
              value={100} 
              status="complete"
              className="mb-6"
              title="🎉 Generation Complete!"
              description="Your document has been successfully generated with AI-powered insights."
            />
            <div className="mt-8 flex justify-end gap-3">
              <button
                onClick={() => setGenerationCompleted(false)}
                className="px-5 py-2.5 text-sm font-medium text-white bg-gradient-to-r from-green-600 to-emerald-600 hover:from-green-700 hover:to-emerald-700 rounded-lg transition-all duration-200 shadow-lg hover:shadow-green-500/25"
              >
                View Document
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
}

