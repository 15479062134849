import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';

interface StepperProgressProps {
  steps: string[];
  currentStep: number;
  isStepCompleted: (step: number) => boolean;
  className?: string;
}

interface ValueProgressProps {
  value: number;
  status?: 'processing' | 'error' | 'complete';
  className?: string;
  title?: string;
  description?: string;
}

type ProgressProps = StepperProgressProps | ValueProgressProps;

const isStepperProgress = (props: ProgressProps): props is StepperProgressProps => {
  return 'steps' in props;
};

export const Progress: React.FC<ProgressProps> = (props) => {
  if (isStepperProgress(props)) {
    const { steps, currentStep, isStepCompleted, className = '' } = props;
    return (
      <div className={`w-full max-w-3xl mx-auto ${className}`}>
        <div className="flex justify-between">
          {steps.map((step, index) => (
            <div key={index} className="flex flex-col items-center">
              <div className="relative">
                <motion.div
                  className={`w-8 h-8 rounded-full flex items-center justify-center border-2 ${
                    index === currentStep
                      ? 'border-blue-500 bg-blue-50'
                      : isStepCompleted(index)
                      ? 'border-green-500 bg-green-50'
                      : 'border-gray-300 bg-white'
                  }`}
                  initial={false}
                  animate={{
                    scale: index === currentStep ? 1.2 : 1,
                  }}
                  transition={{ duration: 0.2 }}
                >
                  {isStepCompleted(index) ? (
                    <CheckCircle className="w-4 h-4 text-green-500" />
                  ) : (
                    <span
                      className={`text-sm font-medium ${
                        index === currentStep ? 'text-blue-500' : 'text-gray-500'
                      }`}
                    >
                      {index + 1}
                    </span>
                  )}
                </motion.div>
                {index < steps.length - 1 && (
                  <div
                    className={`absolute top-1/2 -translate-y-1/2 left-8 w-[calc(100%-2rem)] h-0.5 ${
                      isStepCompleted(index) ? 'bg-green-500' : 'bg-gray-300'
                    }`}
                  />
                )}
              </div>
              <span
                className={`mt-2 text-sm ${
                  index === currentStep
                    ? 'text-blue-500 font-medium'
                    : isStepCompleted(index)
                    ? 'text-green-500'
                    : 'text-gray-500'
                }`}
              >
                {step}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  }

  const { value, status = 'processing', className = '', title, description } = props;
  
  const getStatusColor = () => {
    switch (status) {
      case 'processing':
        return 'bg-blue-500';
      case 'error':
        return 'bg-red-500';
      case 'complete':
        return 'bg-green-500';
      default:
        return 'bg-blue-500';
    }
  };

  return (
    <div className={className}>
      {title && (
        <h3 className="text-lg font-semibold text-gray-900 mb-2">{title}</h3>
      )}
      {description && (
        <p className="text-sm text-gray-600 mb-4">{description}</p>
      )}
      <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
        <motion.div
          className={`h-2.5 rounded-full ${getStatusColor()}`}
          initial={{ width: 0 }}
          animate={{ width: `${value}%` }}
          transition={{ duration: 0.5 }}
        />
      </div>
    </div>
  );
};

